import React from 'react'
import Nav from "../../components/Nav";
import navItems from "../../components/Nav/index.json";
import "./index.css"
import subnavItems from "./index.json";

function Adhesions() {

    return (
        <>
            <Nav items={navItems.urls} />
                <div className="Adhesions-Core">
                <Nav items={subnavItems.urls} sub />
                
                <div className="Adhesions Adhesions-Intro" id="musculation-halterophilie">
                    <p className="Adhesions-Title">Musculation-Haltérophilie</p>
                    <p className="Adhesions-Text">
                    <div className="Adhesions-Left">
                            Lundi au vendredi : <strong>17h-21h30</strong><br />
                            Samedi: <strong>10h30-12h30 & 16h à 19h30</strong><br />
                        </div>
                        <div className="Adhesions-Right Adhesions-RightTrue">
                            <strong className="Adhesions-subtitle">Séances en autonomie ou avec suivi sportif encadré par un éducateur :</strong> <br />
                            Nouvel adhérent : <strong>200€</strong><br />
                            Ancien adhérent : <strong>170€</strong><br /><br />
                            Séance d’essai en autonomie <strong>5€</strong><br />
                            Suivi sportif sur la saison 1 fois par semaine de 60 minutes <strong className="Adhesions-subtitle">(réservé aux adhérent.e.s)</strong> : <strong>+90€</strong><br /><br />
                            <strong className="Adhesions-subtitle">Coaching individuel (réservé aux adhérent.e.s) :</strong> <br />
                            5 séances de 60 minutes chacun :  <strong>200€</strong><br />
                    </div>
                    </p>
                </div>

                <div className="Adhesions Adhesions-Intro" id="ordonnance">
                    <p className="Adhesions-Title">Sport-Santé sur ordonnance : Cours collectifs</p>
                    <p className="Adhesions-Text">
                    <div className="Adhesions-Left">
                            Mercredi : <strong>17h à 18h</strong><br />
                        </div>
                        <div className="Adhesions-Right Adhesions-RightTrue">
                            Cours collectifs variés avec adaptations : <strong>100€</strong> <br />
                            <span className="Adhesions-mention">Remboursement possible par certaines mutuelles de santé</span><br />
                            Ou : <strong>20€ selon revenus</strong><br />
                            Première séance d’essai : <strong>gratuite sur RDV</strong>
                    </div>
                    </p>
                </div>

                <div className="Adhesions Adhesions-Intro" id="sport-sante">
                    <p className="Adhesions-Title">Muscu'Santé/Cardio</p>
                    <p className="Adhesions-Text">
                    <div className="Adhesions-Left">
                        Lundi au samedi : <strong>2 séances/semaine sur RDV</strong><br />
                        </div>
                        <div className="Adhesions-Right Adhesions-RightTrue">
                            <strong className="Adhesions-subtitle">Séances adaptées avec coaches et bilans de forme :</strong><br />
                            Nouvel adhérent : <strong>290€</strong><br />
                            Ancien adhérent : <strong>260€</strong><br />
                            <span className="Adhesions-mention">Remboursement possible par certaines mutuelles de santé</span><br /><br />
                            Bilans conditions physiques inclus avec entretien motivationnel.<br />
                            Première séance d’essai sur RDV : <strong>5€</strong>. Les suivantes : <strong>20€</strong>.
                    </div>
                    </p>
                </div>

                <div className="Adhesions Adhesions-Intro" id="musculation-seniors">
                    <p className="Adhesions-Title">Muscu'Seniors</p>
                    <p className="Adhesions-Text">
                    <div className="Adhesions-Left">
                        Mercredi : <strong>17h à 18h</strong><br />
                        </div>
                        <div className="Adhesions-Right Adhesions-RightTrue">
                            Atelier de renforcement musculaire avec adaptations pour personnes de + de 60 ans :  <strong>20€</strong><br />
                            Programme passerelle de 3 mois<br />        
                            Première séance d’essai sur RDV : <strong>gratuite</strong>
                    </div>
                    </p>
                </div>

                <div className="Adhesions Adhesions-Intro" id="musculation-jeunes">
                    <p className="Adhesions-Title">Muscu'Jeunes</p>
                    <p className="Adhesions-Text">
                    <div className="Adhesions-Left Adhesions-LeftTrue">
                            Mardi & jeudi : <strong>19h à 20h</strong><br />
                            Samedi : <strong>16h-17h ou 17h-18h</strong><br /><br />
                            Cours collectifs de musculation encadrés avec adaptations pour mineurs de 12 à 16 ans.<br />
                            Conseils en santé et hygiène de vie. Prévention malbouffe et sédentarité.
                        </div>
                        <div className="Adhesions-Right">
                            Nouvel adhérent : <strong>290€</strong><br />
                            Ancien adhérent : <strong>260€</strong><br />
                            <span className="Adhesions-mention">Réduction de -20% à -70% grâce aux coupons CCAS de la mairie de Créteil<br /> (sous conditions de revenus) <a href="https://www.ville-creteil.fr/prestations-sociales#sportetculture">(Ville de Créteil - Prestations sociales)</a></span><br />
                            Première séance d’essai sur RDV avec autorisation parentale : <strong>5€</strong>
                    </div>
                    </p>
                </div>

                <div className="Adhesions Adhesions-Intro" id="marche-posturale">
                    <p className="Adhesions-Title">Marche posturale</p>
                    <p className="Adhesions-Text">
                    <div className="Adhesions-Left">
                            Samedi: <strong>10h30 à 11h45</strong><br />
                            <span className="Adhesions-mention">RDV au gymnase Casalis (Créteil) à 10h20</span>
                        </div>
                        <div className="Adhesions-Right Adhesions-RightTrue">
                            Marche active avec exercices de musculation et de postures : <strong>150€</strong><br />
                            Première séance d’essai sur RDV : <strong>5€</strong>
                    </div>
                    </p>
                </div>

                <div className="Adhesions Adhesions-Intro" id="cross-training">
                    <p className="Adhesions-Title">Cross-Training</p>
                    <div className="Adhesions-Text Adhesions-LastText">
                        <div className="Adhesions-Left Adhesions-LeftTrue">
                            Lundi & vendredi : <strong>19h à 20h</strong> (en extérieur)<br />
                            Mercredi : <strong>17h-18h30</strong> (en intérieur)<br />
                            Samedi & dimanche : <strong>9h30-10h30</strong> (en extérieur)<br /><br />
                            Cours collectifs de cross-training en extérieur et en intérieur. <br />Adaptations possibles selon niveaux de pratique.
                        </div>
                        <div className="Adhesions-Right">
                            (tarif unique semaine et week-end)<br />
                            Anciens adhérents : <strong>290€</strong> <br />
                            Nouveaux adhérents : <strong>320€</strong> <br />
                            
                            Formule tout compris + salle musculation : <strong>420€</strong> (390€ anciens adhérents)<br />
                            Séance d’essai sur réservation : <strong>5€</strong>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Adhesions