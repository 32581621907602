import React from 'react'
import Nav from "../../components/Nav";
import navItems from "../../components/Nav/index.json";
import subnavItems from "./index.json";
import "./index.css"


function Activites() {

    const isMobile = window.matchMedia("(max-width: 45.5em)").matches;

  return (
    <>
      <Nav items={navItems.urls} />
      <div className="Activites-Core">
        <div className="Activites-Intro">
            <p className="Activites-Title">Nos différentes activités</p>
            <p className="Activites-Text">
            Notre mode de vie moderne est de plus en plus sédentaire. Les progrès technologiques (ascenseurs, voitures, PC, Internet, … ), le développement du télétravail et les achats en ligne facilitent notre quotidien mais limitent au maximum nos efforts.<br />
            Résultat : notre corps s’affaiblit et nous ne pratiquons pas assez d'activités physiques quotidiennes pour le maintenir en bonne santé.<br />
            Dans le même temps l’offre alimentaire explose, la malbouffe et les fast-food sont partout, avec pour conséquences des déséquilibres nutritionnels à l’origine du surpoids et de l’obésité.<br />
            Dans ce contexte, la nécessité de pratiquer des activités physiques et sportives dédiées à la forme et au bien-être s’impose comme une évidence.<br />
            Notre Maison Sport-Santé rassemble en un même lieu plusieurs activités liées aux sports de la forme et de la force que vous pourrez pratiquer en intérieur ou en extérieur quelque soit votre niveau (débutant ou sportif) ou votre pathologie.
            <br /><br />
            </p>
        </div>
        <Nav items={subnavItems.urls} sub />
        <div className="Activite-Photos">
        <div className="Activite-Core">
            <div className="Activite Activite-Intro" id="ordonnance">
                    <p className="Activite-Title">Sport-Santé sur ordonnance: cours collectifs variés</p>
                    <p className="Activite-Text">
                    Réservées aux personnes avec une affection de longue durée ou une maladie chronique, les séances de cours collectifs vous permettront de pratiquer de la musculation et du cardio-training adaptés à votre pathologie et votre état de santé.<br />
                    Ces activités se concrétisent par du renforcement musculaire et des exercices cardio-vasculaires pouvant contribuer à votre bien-être et à la prévention des chutes et au regain de l'autonomie.<br />
                    Un plus non négligeable à cette activité : la découverte de diverses disciplines sport-santé et de bien-être grâce à des interventions ponctuelles de nos partenaires.
                    <br /><br />
                    </p>
                </div>
                { !isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Sport Santé" src={require('../../images/activites/sport-sante.jpg')} /></div> : "" }
            </div>
            { isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Sport Santé" src={require('../../images/activites/sport-sante.jpg')} /></div> : "" }
            <div className="Activite-Core" id="halterophilie">
                <div className="Activite ActiviteImg ActiviteImgLeft">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/yuyI3AV9Idk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <div className="Activite Activite-Intro">
                    <p className="Activite-Title">Haltérophilie</p>
                    <p className="Activite-Text">
                    L'haltérophilie demande coordination, force, souplesse et explosivité. C’est le seul sport de force encore au programme des Jeux Olympiques !<br />
                    Ce sport se décompose de deux mouvements qui sont respectivement l'arraché et l'épaulé-jeté. Chacun de ces mouvements nécessite un apprentissage semi-technique et technique préalable pour progresser de façon optimum.<br />
                    L’haltérophilie c’est aussi « l’école du dos ». En effet, cette discipline est également une très bonne école posturale, de laquelle on tire un véritable bénéfice dans la vie quotidienne : elle apprend à bien placer son dos et son corps en général.<br /><br />
                    L’US Créteil HMF vous propose de pratiquer l'haltérophilie dans une optique de santé ou de loisir afin de lutter contre les effets néfastes de la sédentarité ou corriger vos problèmes de dos.
                    <br /><br />
                    </p>
                </div>
            </div>
            <div className="Activite-Core">

                <div className="Activite Activite-Intro" id="sport-sante">
                    <p className="Activite-Title">Sport-Santé : musculation/cardio</p>
                    <p className="Activite-Text">
                    Notre équipe d'éducateurs sportifs vous accueille sur RDV deux fois par semaine sur des créneaux de 60 minutes que vous choisissez (lundi, mercredi et vendredi de 17h à 21h, mardi et jeudi de 17h à 19h et samedi de 15h à 16h).<br />
                    Vos séances sont adaptées à partir d'exercices de musculation, de renforcement musculaire et d'exercices cardiovasculaires, visant à l'amélioration générale de l'état de santé.<br />
                    Après avoir fait un bilan de votre condition physique et de votre motivation, vous pourrez bénéficier d'un parcours sport-santé et bien-être avec des séances sportives en petits groupes.<br />
                    Que vous soyez en bonne santé, porteur d'une pathologie ou en reprise d'une activité physique, cette activité vous permettra de pratiquer à votre rythme et avec un suivi de votre condition physique et mentale.<br />
                    Le sport-santé peut vous être conseillé par un médecin dans le cadre du dispositif du Sport sur ordonnance si vous avez une affection de longue durée, <strong>une maladie chronique, si vous présentez un facteur de risque ou si vous êtes en situation de perte d’autonomie.</strong>
                    <br /><br />
                    </p>
                </div>
                { !isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Section Sport-Santé - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/muscu-cardio.jpg')} /></div> : ""}
            </div>
                { isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Section Sport-Santé - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/muscu-cardio.jpg')} /></div> : ""} 
            <div className="Activite-Core">
            <div className="Activite ActiviteImg ActiviteImgLeft">
                    <img alt="Section Musculation Jeunes au Gymnase Paul Casalis - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/musculation-jeunes.png')} />
                </div>
                <div className="Activite Activite-Intro" id="musculation-jeunes">
                    <p className="Activite-Title">Muscu’Jeunes</p>
                    <p className="Activite-Text">
                    La Muscu’jeunes est un projet sport-santé de lutte contre la sédentarité et l’inactivité physique pour les adolescents de 12 à 16 ans.<br />
                    Nos ados sont de moins en moins actifs et adoptent des comportements sédentaires qui peuvent être la cause de nombreuses maladies chroniques (diabète, obésité, hypertension, dépression…)<br />
                    La Maison sport-santé de Créteil/Mont-Mesly souhaite contribuer à la bonne santé des jeunes. Le parcours Muscu’Jeunes se concrétise de la manière suivante :
                    <ul className="Activite-MuscuJeuneListe">
                        <li>Des tests pour évaluer la condition physique de votre enfant et des entretiens motivationnels sport-santé réguliers.</li>
                        <li>Des ateliers de musculation encadrés et adaptés à la condition physique de votre enfant 2 à 3 fois par semaine en  groupe.</li>
                        <li>Des conseils nutritionnels et bien-être afin de permettre à votre enfant d’avoir une meilleure alimentation et hygiène de vie.</li>
                    </ul>
                    <span>Voir la vidéo de l'article suivant réalisée par les équipes de <a href="https://www.france.tv/france-5/le-magazine-de-la-sante/" rel="noopener noreferrer" target="_blank">France 5</a> au sein de notre Maison Sport-Santé et relayée par <a target="_blank" rel="noopener noreferrer" href="https://www.allodocteurs.fr/la-mode-de-la-musculation-sur-les-reseaux-un-danger-pour-les-ados-34796.html">allodocteurs.fr</a></span>
                    </p>
                </div>
            </div>
            <div className="Activite-Core">
                <div className="Activite Activite-Intro" id="musculation-seniors">
                    <p className="Activite-Title">Muscu’Seniors</p>
                    <p className="Activite-Text">
                        Dans le cadre de notre programme « <strong>Bouger à Créteil pour sa santé</strong> » (soutenu par la CPAM du val de marne, le GPSEA et le Ministère des Sports) et dans le cadre du programme national « Vie-Eux », la Maison Sport-Santé de Créteil Mont-Mesly propose de faire une place spécifique aux seniors (femmes et hommes de plus de 60 ans, avec pathologies médicales ou pas).<br />
                        Menée par notre enseignant en activités physiques adaptées Nicolas Coeuret, l’activité se structure par la pratique de la musculation santé dans le cadre d’un programme passerelle de trois mois qui inclut aussi des ateliers alimentation et un apprentissage aux outils numériques et des temps ETP (éducation thérapeutique du patient). <br /><br />
                        <strong className="Activites-blue">Contact / inscription :</strong> <br /><span className="Activites-orange">Par email :</span> <a href="mailto:maison.sport.sante.creteil@gmail.com">maison.sport.sante.creteil@gmail.com</a><br /><span className="Activites-orange">Par sms :</span> <a className="Activites-sms" href ="sms:+33783086317">07 83 08 63 17</a> (Linda)<br /><br />
                        La première séance constituera un essai, à l’issu duquel vous aurez un entretien motivationnel et la programmation d’un entretien de capacité physique (pour évaluer vos besoins et limitations). Pensez à une tenue confortable et éventuellement à votre traitement médical.
                    </p>
                </div>
                { !isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Section Musculation Séniors au Gymnase Paul Casalis - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/muscu-seniors.jpg')} /></div> : "" }
            </div>
                { isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Section Musculation Séniors au Gymnase Paul Casalis - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/muscu-seniors.jpg')} /></div> : "" }
            <div className="Activite-Core" id="cross-training">
                <div className="Activite ActiviteImg ActiviteImgLeft">
                    <img alt="Section Cross Training - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/cross-training.jpg')} />
                </div>
                <div className="Activite Activite-Intro">
                    <p className="Activite-Title">Cross-Training</p>
                    <p className="Activite-Text">
                    Le cross-training est une activité sportive pluridisciplinaire qui a la particularité de solliciter tous les muscles du corps, y compris le cœur !<br />
                    Il se base sur des exercices variés, des mouvements fonctionnels et des entraînements à haute intensité.<br />
                    Il permet de développer l’essentiel des qualités physiques : l’endurance musculaire et cardiovasculaire, la force, la souplesse, la puissance, la vitesse, la coordination, l’agilité, l’équilibre et la précision.<br />
                    Les séances de cross-training ont lieu en extérieur les lundis, vendredis, samedis et dimanches et en intérieur les mercredis.<br />
                    Le cross-training est pratiqué en groupe dans un cadre de loisir et de bien-être.<br />
                    Notre éducateur sportif adapte la séance en fonction des objectifs et des limitations de chacun.
                    </p>
                </div>
            </div>
            <div className="Activite-Core">
                <div className="Activite Activite-Intro" id="marche-posturale">
                    <p className="Activite-Title">Marche posturale</p>
                    <p className="Activite-Text">
                    La Maison Sport-Santé de Créteil/Mont-Mesly vous propose une activité physique innovante et ludique inspirée de la marche nordique.<br />
                    La marche posturale se pratique avec des bâtons et intègre des exercices de renforcement musculaire issus de la musculation et de l’haltérophilie.<br />
                    Cette activité physique de sport-santé vise à améliorer les capacités cardio-respiratoires par la marche active et contribue à renforcer et à tonifier l’ensemble des muscles du corps, elle aide à la perte de masse grasse et affine la silhouette.<br />
                    Pratiquée régulièrement en groupe, la marche posturale apporte du bien-être et permet de lutter et de prévenir le mal de dos et d’autres pathologies.<br />
                    Des exercices de musculation et d’étirements sont réalisés à chaque séance afin d’améliorer la souplesse pour un meilleur confort articulaire.
                    </p>
                </div>
                { !isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Marche posturale dans Créteil - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/marche_posturale.png')} /></div> : "" }
            </div>
                { isMobile ? <div className="Activite ActiviteImg ActiviteImgRight"><img alt="Marche posturale dans Créteil - Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/activites/marche_posturale.png')} /></div> : "" }
            <div className="Activite-Core" id="portes-ouvertes">
            <div className="Activite ActiviteImg ActiviteImgLeft">
                <img alt="Marche Posturale" src={require('../../images/sport-sante.png')} />
                </div>
                <div className="Activite Activite-Intro">
                    <p className="Activite-Title">Portes Ouvertes</p>
                    <p className="Activite-Text">
                    Grâce au soutien de nos partenaires, dans un but de prévention primaire de maladies, nous sommes engagés à faire de nombreuses actions sous formes de portes-ouvertes tout au long de l'année. Une grande partie de nos portes-ouvertes se font sous forme de journées thématiques liées au calendrier de l'ONU (journée mondiale du diabète, journée mondiale de la santé mentale, journée mondiale de l'alimentation, journée internationale des femmes, journée mondiale de la santé, etc...).
                    <a target="_blank" rel="noopener noreferrer" href="https://www.un.org/fr/observances/list-days-weeks">Liste des journées et semaines internationales | Nations Unies</a><br />
                    De nombreuses autres portes-ouvertes se font sous l'impulsion d'organismes français qui mènent de fortes campagnes de préventions et de sensibilisation auprès de publics cibles (Ligue contre le cancer pour Octobre Rose ou Mars bleue, Fédération française de cardiologie pour les Parcours du Cœur, etc...).<br />
                    Nos portes-ouvertes sont gratuites, Vous pourrez y pratiquer des activités variées (muscu’santé, boxe santé, art thérapie, marche posturale, jardiner-santé, atelier cuisine, etc.), rencontrer des professionnels en activités physiques et sportives adaptées, des intervenants en Éducation thérapeutique du patient, des spécialistes médicaux et paramédicaux. Ce sont des moments importants de la vie de notre Maison sport-santé.<br />
                    </p>
                </div>

            </div>
          <div className="Activite-Core" id="pratique-loisir">
                <div className="Activite Activite-Intro">
                    <p className="Activite-Title">Pratique Loisir</p>
                    <p className="Activite-Text">
                    La pratique loisir au sein de la Maison sport-santé du Mont-Mesly concerne la section musculation de l’US Créteil HMF.<br />
                    La musculation est une activité dont le but est de faire travailler ses muscles, avec une résistance qui peut être le poids du corps ou une charge légère, moyenne ou lourde en fonction des objectifs recherchés. Les différentes méthodes de musculation permettent de travailler toutes les qualités physiques : endurance, puissance, force, tonicité musculaire, volume musculaire...<br />
                    La musculation pratiquée régulièrement en prévention primaire est bénéfique pour votre santé physique et mentale.<br />
                    Au sein de l’US Créteil HMF, la musculation peux aussi être pratiquée dans un but de compétition lors de divers championnats et des Muscu’Challenges qui sont organisés plusieurs fois dans l’année. Notre club dispose de tout le matériel pour vous permettre de pratiquer de manière efficace et sécurisée. Plus de 30 postes de travail, des haltères de 1kg à 40kg et une cage de cross-training vous permettra de varier les exercices et les programmes d’entraînements.
                    </p>
                </div>
                { !isMobile ? <div className="Activite ActiviteImg ActiviteImgRight">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZTtgsA45Qf4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div> : "" }
            </div>
                { isMobile ? <div className="Activite ActiviteImg ActiviteImgRight">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZTtgsA45Qf4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div> : "" }
        </div>
      </div>
    </>
  );
}

export default Activites
